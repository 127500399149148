export enum TeamErrorCodes {
  TEAM_LAST_ADMIN = "T_LA-1",
  TEAM_WRONG_CODE = "T_WC-1",
  TEAM_NOT_FOUND = "T_NF-1",
  TEAM_NOT_FOUND_DELETED = "T_NFD-1",
  TEAM_INVITE_NOT_ALLOWED = "T_INA-1",
  TEAM_INVITE_EXPIRED = "T_IE-1",
  TEAM_INVITE_NOT_FOUND = "T_INF-1",
  TEAM_INVITE_DELETED = "T_ID-1",
  TEAM_INVITE_ACCEPTED = "T_IA-1",
  TEAM_INVITE_ACCESS_CODE = "T_IAC-1",
  TEAM_INVITE_PUBLIC_ACCESS_CODE = "T_IPAC-1",
  TEAM_INVITE_USER_ALREADY_IN_TEAM = "T_IUAIT-1",
  TEAM_INVITE_USER_ALREADY_INVITED = "T_IUAI-1",
}

export function testTeamErrorEnum() {
  // check that every enum value is only used once
  const teamErrorCodesValues = Object.values(TeamErrorCodes);
  const teamErrorCodesSet = new Set(teamErrorCodesValues);
  if (teamErrorCodesSet.size !== teamErrorCodesValues.length) {
    throw new Error("Duplicate values in TeamErrorCodes");
  }
}
